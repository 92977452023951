import React, { useState, useEffect } from 'react';

import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { useEthers, useContractFunction } from '@usedapp/core'

import { abi } from '../assets/smartcontracts/abi';

import web3 from 'web3';

// -- STYLES
import '../styles/admin.css'

const contract_interface = new utils.Interface(abi);
const contract_address = '0x44cdc48b031f5267316b6a4dae1904225ab95256';
const contract = new Contract(contract_address, contract_interface)

function Admin_Page() {

    let function_names = ['withdraw_all','change_frost'];
    let function_calls = [];
    let function_states = [];

    let withdraw_items = useContractFunction(contract, 'withdraw_all', { transactionName: 'withdraw_all' });
    let withdraw_all = withdraw_items.send;

    // let change_current_max_items = useContractFunction(contract, 'changeCurrentMax', { transactionName: 'changeCurrentMax' });
    // let change_current_max = change_current_max_items.send;

    // let change_price_items = useContractFunction(contract, 'changePrice', { transactionName: 'changePrice' });
    // let change_price = change_price_items.send;

    let change_transfer_items = useContractFunction(contract, 'change_frost', { transactionName: 'change_frost' });
    let change_transfer = change_transfer_items.send;
    

    // console.log(send_withdraw)

    // const withdraw_all = function_calls[0];


    // const mint_test = () => {
    //     send(2, { value: utils.parseEther("0.1") })
    // }

    // useEffect(() => {
    //     console.log(state)
    // }, [state])

    
    const { activateBrowserWallet, account } = useEthers()
    // const { sendTransaction, state } = useSendTransaction()

    return (
        <div className='control_pannel'>
            <div>
                <button onClick={() => activateBrowserWallet()}>Connect</button>
            </div>
            <br/>
            {account && <p>Account: {account}</p>}
            
            <br/><hr/><br/>
            <div>
                <h3>Withdraw</h3><br/>
                <button onClick={() => withdraw_all()}>Withdraw All</button>
            </div>
            {/* <div>
                <h3>Max Mintable Token</h3>
                <input type="number" placeholder="max index" id="mintable_index" />
                <button onClick={() => change_current_max(parseInt(document.querySelector('#mintable_index').value))}>Set</button>
            </div>
            <div>
                <h3>Token Price</h3>
                <input type="number" placeholder="price in eth" id="token_price" />
                <button onClick={() => {
                        let new_price = web3.utils.toWei(document.querySelector('#token_price').value,'ether');
                        change_price(new_price)
                    }
                }>Set</button>
            </div> */}

            <br/><hr/><br/>
            <div>
                <h3>Dood Frost</h3><br/>

                <select id="transfer_index">
                    <option value="true">True</option>
                    <option value="false">False</option>
                </select>
                {/* <input type="number" placeholder="max index" id="transfer_index" /> */}
                <button onClick={() => {
                    let state = document.querySelector('#transfer_index').value == "true";
                    console.log(state)
                    change_transfer(state)
                }}>Set</button>
            </div>
        </div>
    )
}

export default Admin_Page;