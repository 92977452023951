// -- STYLES
import '../styles/common.css';
import '../styles/containers.css';
import '../styles/header.css';
import '../styles/minting_bar.css';
import '../styles/image_gallery.css';
import '../styles/intro_component.css';

// -- COMPONENTS
import Footer_Component from '../components/footer.js';
import Header_Component from '../components/header.js';

// -- STYLES
import '../styles/common.css';
import '../styles/containers.css';
import '../styles/header.css';
import '../styles/minting_bar.css';
import '../styles/image_gallery.css';
import '../styles/intro_component.css';

function TOS_Text() {
    return (
        <div className='component component_magin_top standard_text'>
            <h2>Terms</h2>
            <p><br/></p>
            <p>Terms of Use and Service</p>
            <p><br/></p>
            <p><strong>OVERVIEW</strong></p>
            <p><br/></p>
            <p>PLEASE READ THESE TERMS OF USE &amp; SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS. THEY INCLUDE A MANDATORY ARBITRATION AGREEMENT AND CLASS ACTION WAIVER WHICH (WITH LIMITED EXCEPTIONS) REQUIRE ANY DISPUTES BETWEEN US TO BE RESOLVED THROUGH INDIVIDUAL ARBITRATION RATHER THAN BY A JUDGE OR JURY IN COURT.</p>
            <p><br/></p>
            <p>Thank you for visiting this page. Terms of Use &amp; Service include the terms of use of this website, as well as the terms of purchase of our products/services. Please read these terms carefully and thoroughly.</p>
            <p><br/></p>
            <p>You are bound by the terms of use when you visit our website and other online services that we provide. You are bound by the terms of purchase when you purchase or use any of our products.</p>
            <p><br/></p>
            <p>Throughout the site, the terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to 100 Fire Ltd, also referred to here as &ldquo;LUCKY DOODS&rdquo;, the brand name used for the specific services described in this website. LUCKY DOODS offers this website, and our services, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
            <p><br/></p>
            <p>By visiting our site and/or purchasing something from us, you engage in our &ldquo;Service&rdquo; and agree to be bound by the following terms and conditions (&ldquo;Terms of Use &amp; Service&rdquo;, &ldquo;Terms&rdquo;), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, and/or customers.</p>
            <p><br/></p>
            <p>By visiting the websites of our products&rsquo; merchants, for instance <a href="http://www.opensea.io">www.opensea.io</a>, or other marketplaces, and <a href="http://www.ordinals.com">www.ordinals.com</a>, you also agree to be bound by their terms and conditions.</p>
            <p><br/></p>
            <p>Please read these Terms of Use &amp; Service carefully before accessing or using our website, or buying any of our services. By accessing or using any part of the site, you agree to be bound by these Terms of Use &amp; Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. You also agree to our Privacy Policy.</p>
            <p><br/></p>
            <p>Any new features or tools which are added to the current site shall also be subject to the Terms of Use &amp; Service. You can review the most current version of the Terms of Use &amp; Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Use &amp; Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website and Service following the posting of any changes constitutes acceptance of those changes.</p>
            <p><br/></p>
            <p>Our website is hosted on Google Firebase.</p>
            <p><br/></p>
            <p><strong>SECTION 1 - ONLINE TERMS</strong></p>
            <p><br/></p>
            <p>By agreeing to these Terms of Use &amp; Service, you represent that you are at least the age of majority in your state or province of residence.</p>
            <p><br/></p>
            <p>You may not use our products and/or services for any illegal or unauthorised purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
            <p><br/></p>
            <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
            <p><br/></p>
            <p><strong>SECTION 2 - GENERAL CONDITIONS</strong></p>
            <p><br/></p>
            <p>You must not transmit any worms or viruses or any code of a destructive nature when using our website or Services. A breach or violation of any of the Terms will result in an immediate termination of our Services to you.</p>
            <p><br/></p>
            <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
            <p><br/></p>
            <p><strong>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</strong></p>
            <p><br/></p>
            <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
            <p><br/></p>
            <p><strong>SECTION 4 - MODIFICATIONS TO THE INFORMATION, SERVICE AND PRICES</strong></p>
            <p><br/></p>
            <p>Information, services and prices are subject to change without notice.</p>
            <p><br/></p>
            <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
            <p><br/></p>
            <p>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
            <p><br/></p>
            <p>Prices for our products on merchant websites to which our links point, such as prices on Opensea.io, may be subject to changes. Please note that we bear no responsibility for any of our products offered on opensea.io or other merchant sites when such offers originate from third parties (secondary market).</p>
            <p><br/></p>
            <p><strong>SECTION 5 - PRODUCTS AND SERVICES</strong></p>
            <p><br/></p>
            <p>Certain products or services may be available exclusively online through the website.</p>
            <p><br/></p>
            <p>We have made every effort to display as accurately as possible the colours and images of our products and services that appear on our website. We cannot guarantee that your computer monitor&apos;s display of any colour will be accurate.</p>
            <p><br/></p>
            <p>We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
            <p><br/></p>
            <p>We also may run a Discord server, for information, promotion, and/or members discussions. Please note that we reserve ourselves the right to reject or ban any member that infringes on our rules. Purchasing of any of our product does not confer the buyer any right of access to such Discord server, and we may block or ban the access of anyone we see fit, for any reason, and without any warning or notice. We may also terminate such server at any time at our entire discretion and without prior notice.</p>
            <p><br/></p>
            <p>Characteristics of the products, such as traits, tiers, and/or grades, as well as their respective rarities and probabilistic repartitions, at NFT issue, or later, and/or overall, may change or be changed according to circumstances and at our entire discretion.</p>
            <p><br/></p>
            <p>When purchasing one of our NFTs from us, you acquire a non-exclusive, world-wide, royalty-free, license to use, copy, display the related artworks associated with the NFT, for you own personal, non-commercial use. However, you do not own the underlying copyright in the content the NFT is associated to, nor any copyright related to the component images and graphics, technology or process used in the making of the artworks or of the NFT. Furthermore, you are getting the NFT and license &ldquo;as is&rdquo;. You can use the artworks when you are on a marketplace that allows the purchase and sales of your token, as long as the marketplace cryptographically verifies that you are the owner. You can also use your artworks to commercialise your own merchandise, provided that you do not earn more than US$100,000 in revenue each year from doing so. However, you cannot modify the artworks, use the artworks to sell or promote third party products, use the artworks in connection with images of hatred, violence or any other inappropriate behaviour. You cannot try to trademark the artworks, or otherwise acquire intellectual property rights in them.</p>
            <p><br/></p>
            <p>The License granted in above Section 6-1 only applies to the extent that you continue to own the relevant NFT. If at any time you trade, donate, giveaway, transfer or otherwise dispose of your NFT for any reason, the license granted in Section 6-1 will immediately expire and without notice, and you will have no further right in or to the artwork of this NFT.</p>
            <p><br/></p>
            <p>Except for our custodial and maintenance works, you accept that there is no explicit or implicit obligation for us to undertake significant changes to the Service that we offer and provide. In particular, we are under no obligation to provide additional features, functionalities, or services, and to promote the products/services, the brand name, or anything related to our product/service that you bought from us or from third parties.&nbsp;</p>
            <p><br/></p>
            <p>We do not fully guaranty the availability of mint (buy for free or purchase) any of our products, as there may be at times technical limitations with the blockchain transactions, or the wallets that you use.</p>
            <p><br/></p>
            <p>All sales are final and cannot be refunded. If you are a citizen of the EU/EEA/UK, you generally enjoy a 14 days cancellation and refund right for purchases done online. However, in view of the irreversible nature of crypto transactions, when purchasing an LUCKY DOODS NFT from us, you specifically renounce this right.</p>
            <p><br/></p>
            <p>As described on our website and/or other online material such as our Discord server / Twitter posts, the NFTs that you buy from us may not be tradable nor transferable at mint, but only after a short period of time, which could range between a few days to a couple of weeks. In particular, you understand that such restriction is inbuilt in the smart contract that controls the NFTs.</p>
            <p><br/></p>
            <p><strong>SECTION 6 &ndash; CRYPTO ITEMS</strong></p>
            <p><br/></p>
            <p>You accept and acknowledge:</p>
            <p><br/></p>
            <p>The prices of crypto items are extremely volatile, and the prices of other digital items can adversely affect the prices of LUCKY DOODS NFTs. We cannot guarantee that any purchaser of crypto item will not lose money. In particular, a number of factors may affect the prices of NFTs, including (but not limited to), buyers&rsquo; interest, market fluctuation, technological changes, regulatory environment.</p>
            <p><br/></p>
            <p>We do not represent that our NFTs may constitute a valuable purchase, or that their value may increase with time. Please do not buy any of our products/services with any expectation of profit. When purchasing any of our NFTs, you accept and confirm that you have no expectation of profit. The NFTs that you buy from us are not investment contracts, and we disclaim any claim associated with any speculation you or other users may engage in connection to these NFTs.</p>
            <p><br/></p>
            <p>You are solely responsible if any taxes apply to your crypto items and transactions. We are not responsible for determining the taxes that may apply to you.</p>
            <p><br/></p>
            <p>We do not store or send your crypto items. Any transfer of crypto items occurs within the supporting blockchain.</p>
            <p><br/></p>
            <p>There are risks associated with using an Internet based currency, including but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorised access to information sorted within your wallet. You accept and acknowledge that we will not be responsible for any communication failures, disruptions, errors, distortions or delay that you may experience when using our services or products, however caused.</p>
            <p><br/></p>
            <p>The regulatory regime governing NFTs and crypto currencies is uncertain, and new regulation or policies may adversely affect the services and products that we provide.</p>
            <p><br/></p>
            <p>There are other risks associated with purchasing NFTs, including but not limited to the risk of purchasing mislabelled assets, assets that are vulnerable to metadata decay, assets on smart contracts with bugs, and assets that may become non-transferable. Assets that your purchase may become inaccessible on our website or merchants&rsquo; website. Under no circumstances shall the inability to view your assets serve as ground for a claim against us.</p>
            <p><br/></p>
            <p>Please note that we reserve ourselves the right to amend our offers, as described on this website, in our discord server, in our tweets, on our white-paper (if any), and/or other places where we advertise our products.</p>
            <p><br/></p>
            <p>You are also aware that there are fraud risks in buying NFTs, and you need to exercise great caution when accessing our links and websites. In particular, we are not responsible for any losses that you may suffer resulting from you responding to third parties impersonating us or our websites, or from parties offering copies of our products/services. In particular, we are not responsible for any third party contacting you with a direct message on our Discord server, and for any losses that you may suffer as a result of you answering such solicitation. We are also not responsible if you mistakenly mint (buy NFT) from a website or a party that fraudulently impersonates us, our website, or our services.</p>
            <p><br/></p>
            <p><strong>SECTION 7 - THIRD-PARTY LINKS</strong></p>
            <p><br/></p>
            <p>Certain content, products and services available via our Service may include materials from third-parties.</p>
            <p><br/></p>
            <p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating their content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>
            <p><br/></p>
            <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party&apos;s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
            <p><br/></p>
            <p><strong>SECTION 8 - PERSONAL INFORMATION</strong></p>
            <p><br/></p>
            <p>Your submission of personal information through the store is governed by our Privacy Policy. Please visit the Privacy section of our website. By agreeing to these Terms, you also agree to our Privacy Policy.</p>
            <p><br/></p>
            <p><strong>SECTION 9 - ERRORS, INACCURACIES AND OMISSIONS</strong></p>
            <p><br/></p>
            <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, or availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel any information in the Service or on any related website that is inaccurate at any time without prior notice.</p>
            <p><br/></p>
            <p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>
            <p><br/></p>
            <p><strong>SECTION 10 - PROHIBITED USES</strong></p>
            <p><br/></p>
            <p>In addition to other prohibitions as set forth in theses Terms of Use &amp; Service, you are prohibited from using our website site and Service (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
            <p><br/></p>
            <p><strong>Restrictions:</strong></p>
            <p><br/></p>
            <p>You may not purchase nor use any of our Services if you are a resident of a country or territories under international sanctions: Afghanistan, Belarus, Cuba, Crimea, Iran, North Korea, Russia, South Sudan, Syria, Venezuela.</p>
            <p><br/></p>
            <p>You may not purchase nor use any of our Services if you are a person or entity under international sanctions, such as under the US Treasury list, or the EU persons sanctions list.</p>
            <p><br/></p>
            <p><strong>SECTION 11 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</strong></p>
            <p><br/></p>
            <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</p>
            <p><br/></p>
            <p>We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.</p>
            <p><br/></p>
            <p>You agree that from time to time, we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.</p>
            <p><br/></p>
            <p>You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and services delivered to you through the service are (except as expressly stated by us) provided &apos;as is&apos; and &apos;as available&apos; for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
            <p><br/></p>
            <p>In no case shall we, our directors, officers, employees, affiliates, agents, contractors, interns, shareholders, suppliers, service providers or licensees be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation, lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or any products procured using the Service or from us, or for any other claim related in any way to your use of the Service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the Service, even if advised of their possibility. Because some countries, states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
            <p><br/></p>
            <p><strong>SECTION 12 &ndash; INDEMNIFICATION</strong></p>
            <p><br/></p>
            <p>You agree to indemnify, defend and hold harmless us and our parent, subsidiaries, affiliates, partners, officers, directors, shareholders, agents, contractors, licensees, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys&rsquo; fees, made by any third-party due to or arising out of your breach of these Terms of Use &amp; Service or the documents they incorporate by reference or your violation of any law or the rights of a third-party.</p>
            <p><br/></p>
            <p><strong>SECTION 13 &ndash; SEVERABILITY</strong></p>
            <p><br/></p>
            <p>In the event that any provision of these Terms of Use &amp; Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Use &amp; Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
            <p><br/></p>
            <p><strong>SECTION 14 - ENTIRE AGREEMENT</strong></p>
            <p><br/></p>
            <p>The failure of us to exercise or enforce any right or provision of these Terms of Use &amp; Service shall not constitute a waiver of such right or provision.</p>
            <p><br/></p>
            <p>These Terms of Use &amp; Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and governs your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Use &amp; Service).</p>
            <p><br/></p>
            <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>
            <p><br/></p>
            <p><strong>SECTION 15 - GOVERNING LAW &amp; DISPUTES</strong></p>
            <p><br/></p>
            <p>These Terms of Use &amp; Service and any separate agreements whereby we provide you Services shall be governed by, and construed in accordance with the laws of Hong Kong SAR.</p>
            <p><br/></p>
            <p><strong>Waiver:</strong></p>
            <p><br/></p>
            <p>The parties (you and us) hereby waive their rights to go to court and have a trial in front of a judge or a jury. In addition, all claims must be arbitrated or litigated on an individual basis and not on a class basis, and claims of more than one customer cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.</p>
            <p><br/></p>
            <p><strong>Claims settlement:</strong></p>
            <p><br/></p>
            <p>In case of any dispute, all parties should try to resolve their differences in connection with the Terms of Use &amp; Service or any product or service provided by us, through informal discussion and negotiation, or through the Hong Kong small claims tribunal.</p>
            <p><br/></p>
            <p>You hereby waive your rights, constitutional, statutory, and otherwise, to go to court and have a trial in front of a judge or a jury. In addition, all claims must be arbitrated or litigated on an individual basis and not on a class basis, and claims of more than one customer cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.</p>
            <p><br/></p>
            <p>All claims and disputes that cannot be settled informally or through the Hong Kong small claims tribunal shall be resolved by binding arbitration, as described below. This arbitration agreement applies to you and us, our directors, officers, employees, affiliates, agents, contractors, interns, shareholders, suppliers, service providers, licensees, successors or assigns. Before either party seeks arbitration, the party must first send a Notice of Dispute describing the nature and basis of the claim or dispute, and the requested relief. A Notice of Dispute should be sent to us by registered mail to us at 100 Fire ltd, 76/F, The Centre, 99 Queen&rsquo;s Road, Central, Hong Kong. If the claim is not resolved within 30 days, either party may begin an arbitration proceeding. Any dispute, controversy, difference or claim arising out of or relating to this contract, including the existence, validity, interpretation, performance, breach or termination thereof or any dispute regarding non- contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (HKIAC) (https://www.hkiac.org/) under the HKIAC Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The law of this arbitration clause shall be the Hong Kong law. The seat of arbitration shall be Hong Kong. The number of arbitrators shall be three. The arbitration proceedings shall be conducted in English. The decisions of the HKIAC shall be binding on each party and final, and all aspects of the arbitration proceeding, including but not limited to the award of the arbitrator, shall be strictly confidential. In any case, any claim against us shall not exceed the amount that you paid us for the services and products.</p>
            <p><br/></p>
            <p><strong>Small claims tribunal:</strong></p>
            <p><br/></p>
            <p>Notwithstanding the foregoing, either you or us may bring an individual action in the small claims tribunal of Hong Kong.</p>
            <p><br/></p>
            <p><strong>SECTION 16 - CHANGES TO TERMS OF USE &amp; SERVICE</strong></p>
            <p><br/></p>
            <p>You can review the most current version of the Terms of Use &amp; Service at any time at this page.</p>
            <p><br/></p>
            <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Use Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Use &amp; Service constitutes acceptance of those changes.</p>
            <p><br/></p>
            <p><strong>SECTION 17 - CONTACT INFORMATION</strong></p>
            <p><br/></p>
            <p>Questions about the Terms of Use &amp; Service should be sent to us at: <a href="mailto:contactluckydoods@gmail.com">contactluckydoods@gmail.com</a></p>
            <p><br/></p>

        </div>
    )
}

function TOS_Page() {
    
    return (
        <div>
            <Header_Component/>
            {TOS_Text()}
            <Footer_Component/>
        </div>
    )
}

export default TOS_Page;