import React, { useState, useEffect } from 'react';

import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { useEthers, useContractFunction, useCall, useUpdateConfig } from '@usedapp/core'

import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";


import { info, check_password, mint_auth, create_cross_mint_wallet, whats_ip } from '../services/api';

import { abi } from '../assets/smartcontracts/abi';

// STYLES
import '../styles/minting.css'

// IMAGES
import minting_machine from '../assets/images/minting_machine.png';
import back_soon_sticker from '../assets/images/back_soon_sticker.png';
import sold_out_sticker from '../assets/images/sold_out_sticker.png';
import smoke_0 from '../assets/images/smoke_0.png';
import smoke_1 from '../assets/images/smoke_1.png';
import smoke_2 from '../assets/images/smoke_2.png';
import smoke_3 from '../assets/images/smoke_3.png';
import gear_0 from '../assets/images/gear_0.png';
import gear_1 from '../assets/images/gear_1.png';
import sattelite_dish from '../assets/images/sattelite_dish.png';
import rainbow_rotated from '../assets/images/rainbow_rotated.png';

let smoke_clouds = [smoke_0,smoke_1,smoke_2,smoke_3]


// -- SETUP SMART CONTRACT
const contract_interface = new utils.Interface(abi);
const contract_address = '0x44cdc48b031f5267316b6a4dae1904225ab95256';
// const contract_address = '0x679f839c286f988fcb518e9d97a552a4dcb1c180'
const contract = new Contract(contract_address, contract_interface)
const tracking_scheme = 'https://etherscan.io/tx'
// const tracking_scheme = 'https://goerli.etherscan.io/tx'

let config_updated = false;
let no_info = false;



function Minting_UI() {

    const updateConfig = useUpdateConfig()

    const { activateBrowserWallet, account } = useEthers();
    const [mintingInfo, setMintingInfo] = useState(null);
    const [mintingPassword, setMintingPassword] = useState(null);
    const [mintingStatus, setMintingStatus] = useState(null);
    const [number_of_doods, set_number_of_doods] = useState(3);
    const [crossMintAccount, setCrossMintAccount] = useState(null);
    const [crossMintParams, setCrossMintParams] = useState({
        lucky_recipient: 0,
        maximum_in_batch: 100,
        maximum_per_transaction: 10,
        price_per_dood: 0.15,
        v: 0,
        r: 0,
        s: 0,
    });
    const [passInfo, setPassInfo] = useState({
        ip_address: 0,
        user_agent: ''
    })

    let mint_items = useContractFunction(contract, 'mint_doods', { transactionName: 'mint_doods' });
    let mint_function = mint_items.send;
    let minting_status = mint_items.state;


    useEffect(() => {
        info().then(data => setMintingInfo(data));

        try {
            window.fbq('track', 'PageView');
        } catch (error) {
        }
        
        
    }, []);

    useEffect(() => {
        setMintingPassword('mint')
        console.log(mintingPassword)
    })


    const [qtySet, setQtySet] = useState(null);

    useEffect(() => {
        let mint_qty = document.querySelector('#mint_qty');
        if (mint_qty && !qtySet && mintingInfo) {
            mint_qty.value = 3;
            mint_qty.min = 1;
            mint_qty.max = mintingInfo.maximum_per_transaction;
            setQtySet(true);
        }
    });

    let show_tx = false;

    useEffect(() => {

        if (mintingStatus && !['PendingSignature','Exception'].includes(minting_status.status)) {
            console.log(minting_status)
            document.querySelector('.transaction_tracker').style.display = 'block';
            setMintingStatus(false);

            try {
                let qty = parseInt(document.querySelector('#mint_qty').value);
                window.fbq('track', 'Purchase', {currency: "USD", value: qty * 250});
                window.twq('event', 'tw-odzbm-odzbq', {value: qty * 250, currency: "USD"});
            
            } catch (error) {
                window.fbq('track', 'Purchase');
                window.twq('event', 'tw-odzbm-odzbq');
            }
            

        }

    }, [minting_status]);


    const { value, error } = useCall(contract_address && {
        contract: contract,
        method: 'totalSupply',
        args: [],
        // refresh: 'never'
    }) ?? {}

    if (error) {
        console.error(error.message)
        value = undefined
    }


    let total_minted = value?.[0].toNumber();
    
    // -- CHANGE POLLING IF NO NEED TO REFRESH ALL THE FREAKING TIME
    if (!config_updated && total_minted != void 0 && mintingInfo != void 0) {
        if (total_minted >= mintingInfo.total_supply || !(Date.now() >= mintingInfo.start_date && total_minted < mintingInfo.maximum_in_batch)) {
            config_updated = true;
            updateConfig({pollingInterval: 1000000})
            no_info = true;
            handle_resize();
        }
    }

    // setMintingPassword('mint')


    function handle_password() {

        let password = "mint"// document.querySelector('#password').value;
        setMintingPassword(password);
        if (!account) { activateBrowserWallet(); }

        // check_password(password).then(() => {
            
        //     setMintingPassword(password);
        //     if (!account) { activateBrowserWallet(); }

        // }).catch((error) => {
        //     console.log(error)
        //     alert("Invalid Password")
        // });


        try {
            window.fbq('track', 'InitiateCheckout');
            window.twq('event', 'tw-odzbm-odzbo');
        } catch (error) {}

    }

    function isValidEmailAddress(address) {
        return !! address.match(/.+@.+/);
    }

    async function handle_credit_card() {


        let password = "mint"
        setMintingPassword(password);

        let credit_card_button = document.querySelector('#credit_card_button')

        credit_card_button.innerHTML = 'Loading...'
        credit_card_button.style = 'opacity: 0.5; pointer-events: none;';

        let email = prompt('Enter your Email Address')
        if (!isValidEmailAddress(email)) {
            alert('Invalid email address')
            credit_card_button.innerHTML = 'Credit Card'
            credit_card_button.style = 'opacity: 1; pointer-events: auto;';
            return;
        }

        try {

            let data = await create_cross_mint_wallet(email);
            let auth = await mint_auth(mintingPassword, data.publicKey);
            let ip_address = await whats_ip();
            let user_agent = window.navigator.userAgent
            setPassInfo(JSON.stringify({ip_address, user_agent}))

            console.log({
                lucky_recipient: data.publicKey,
                maximum_in_batch: auth.maximum_in_batch,
                maximum_per_transaction: auth.maximum_per_transaction,
                price_per_dood: auth.price_per_dood,
                v: auth.signature.v,
                r: auth.signature.r,
                s: auth.signature.s
            })

            setCrossMintParams({
                lucky_recipient: data.publicKey,
                maximum_in_batch: auth.maximum_in_batch,
                maximum_per_transaction: auth.maximum_per_transaction,
                price_per_dood: auth.price_per_dood,
                v: auth.signature.v,
                r: auth.signature.r,
                s: auth.signature.s
            })

            setCrossMintAccount(data.publicKey)

            
        } catch (error) {

        }

        credit_card_button.innerHTML = 'Credit Card'
        credit_card_button.style = 'opacity: 1; pointer-events: auto;';


        // create_cross_mint_wallet('okok').then(data => {

        //     console.log(data)
        //     setIsCrossMint(true)
        // })
        
    }

    async function handle_minting() {

        if (total_minted >= mintingInfo.maximum_in_batch || total_minted >= mintingInfo.total_supply) {
            alert("Oh no! Looks like there are no more Lucky Doods left in this release.")
            return;
        }

        let qty = parseInt(document.querySelector('#mint_qty').value);

        if (total_minted + qty > mintingInfo.maximum_in_batch || total_minted + qty > mintingInfo.total_supply) {
            alert(`Uh oh! There's only ${mintingInfo.maximum_in_batch - total_minted} Lucky Doods left in this release. It looks like you're trying to mint ${qty}. Please adjust your quantity.`)
            return;
        }

        let mint_button = document.querySelector('#mint_button');
        mint_button.style = 'pointer-events: none; opacity: 0.5';
        mint_button.innerHTML = 'Loading...';

        try {

            let auth = await mint_auth(mintingPassword, account);

            // console.log(qty);
            // console.log(0.5 * qty)

            
            let value = 0.1500001 * qty;
            let price_per_dood = utils.parseEther('0.15');
            value = utils.parseEther(value.toString());

            
            mint_function(
                qty,
                account,
                auth.maximum_in_batch,
                auth.maximum_per_transaction,
                price_per_dood,
                // auth.price_per_dood,
                auth.signature.v,
                auth.signature.r,
                auth.signature.s,
                { value }
            )

            mint_button.innerHTML = 'Mint';
            mint_button.style = 'pointer-events: auto; opacity: 1;';

            setMintingStatus(true);

            
        } catch (error) {
            console.log(error);
            alert("Error. Please DM us in Discord");
            mint_button.innerHTML = 'Mint';
            mint_button.style = 'pointer-events: auto; opacity: 1;';
            setMintingStatus(false);
        }


    }

    function close_tracker() {
        document.querySelector('.transaction_tracker').style.display = 'none';
    }

    function increment_qty() {
        let mint_qty = document.querySelector('#mint_qty');
        let qty = parseInt(mint_qty.value);
        qty = qty < mintingInfo.maximum_per_transaction ? qty+1 : qty;
        mint_qty.value = qty;
        set_number_of_doods(qty)
    }

    function decrement_qty() {
        let mint_qty = document.querySelector('#mint_qty');
        let qty = parseInt(mint_qty.value);
        qty = qty > 1 ? qty-1 : qty;
        mint_qty.value = qty;
        set_number_of_doods(qty)
    }

    function handle_input_changed() {
        let mint_qty = document.querySelector('#mint_qty');
        let qty = parseInt(mint_qty.value);
        let max = mintingInfo.maximum_per_transaction;
        qty = qty > max ? max : qty;
        qty = qty < 1 ? 1 : qty;
        mint_qty.value = qty;
        set_number_of_doods(qty)
    }

    function view_transaction() {
        window.open(`${tracking_scheme}/${minting_status.transaction.hash}`)
    }

    function go_to_discord() {
        window.open('https://discord.gg/kWRDbsrEkr')
    }
    

    

    return (
        <div>
            <div className='animation_layer'>
                <img className='gear_0' src={gear_0}/>
                <img className='gear_1' src={gear_1}/>
                <img className='sattelite_dish' src={sattelite_dish}/>
                <div className='rainbow_container'>
                    <img className='rainbow_inner' src={rainbow_rotated}/>
                </div>
            </div>
            <img className='minting_machine' src={minting_machine}/>

            {   
                mintingInfo != void 0 && total_minted != void 0 ?

                    total_minted >= mintingInfo.total_supply ?
                
                    
                        <img className='sticker' src={sold_out_sticker}/>
                        
                        :

                        Date.now() >= mintingInfo.start_date && total_minted < mintingInfo.maximum_in_batch ?
                            <div className='minting_ui_container'>
                                <h3 className='main_title'>Minting Machine</h3>
                                {mintingPassword && (account || crossMintAccount) ?
                                    <div>
                                        <div className='explanation_text'>{`Currently available: ${mintingInfo.maximum_in_batch - total_minted} Doods`}<br/>{`Price: ${mintingInfo.eth_price} Eth per Lucky Dood`}</div>
                                        
                                        <div className='decrement_button unselectable_text' onClick={decrement_qty}>-</div>
                                        <div className='increment_button unselectable_text' onClick={increment_qty}>+</div>
                                        <input className='minting_qty_input' type="number" id="mint_qty" onChange={handle_input_changed}/>
                                        
                                        {crossMintAccount ?
                                            <CrossmintPayButton
                                                className='cross_mint_button'
                                                clientId={"636ac1c0-5154-4e82-843d-b463a6f8d506"}
                                                environment="staging"
                                                mintTo={crossMintParams.lucky_recipient}
                                                mintConfig={{
                                                    type: "erc-721",
                                                    totalPrice: (0.0100001 * number_of_doods).toString(), // <-- PRICE ADJUST
                                                    lucky_recipient: crossMintParams.lucky_recipient,
                                                    number_of_doods: number_of_doods,
                                                    maximum_in_batch: crossMintParams.maximum_in_batch,
                                                    maximum_per_transaction: crossMintParams.maximum_per_transaction,
                                                    price_per_dood: crossMintParams.price_per_dood,
                                                    v: crossMintParams.v,
                                                    r: crossMintParams.r,
                                                    s: crossMintParams.s
                                                }}
                                                whPassThroughArgs={passInfo}
                                            />
                                            :
                                            <button className='main_button unselectable_text' id="mint_button" onClick={handle_minting}>Mint</button>
                                        }
                                        
                                        {/* {minting_status.status != 'None' && console.log(minting_status)} */}
                                    </div>
                                    :
                                    <div>

                                        <div className='explanation_text'>Need help? <u onClick={go_to_discord}>Join our Discord</u> for support</div>
                                        {/* <input type="text" id="password" placeholder="password" className='minting_input'/> */}
                                        {/* <button className='upper_button unselectable_text' onClick={handle_password}>MetaMask</button> */}
                                        {/* <button className='main_button unselectable_text' onClick={handle_credit_card} id="credit_card_button">Credit Card</button> */}
                                        <button className='main_button unselectable_text' onClick={handle_password}>Connect</button>

                                        {/* <button onClick={handle_password}>Connect</button> */}
                                    </div>
                                }
                            </div>
                        :
                            <div>
                                <img className='sticker clickable' src={back_soon_sticker} onClick={go_to_discord}/>
                                
                                {/* { <div>{(new Date(mintingInfo.start_date)).toString()}</div>}
                                

                                { console.log(mintingInfo, Date.now(), mintingInfo.start_date) } */}
                            </div>                
            
                :
                <div className='minting_ui_container'>
                    <h3 className='loading_title'>Loading...</h3>
                </div>
            }
            <div className='transaction_tracker'>
                <div className='tracker_inner'>
                    <h3 className='main_title'>Minting Tracker</h3>
                    <div className='purchase_confirmation'>Congrats your Lucky Dood is on his way! You can track your transaction by <u onClick={view_transaction}>clicking here</u></div>
                    <div className='mint_more_button unselectable_text' onClick={close_tracker}>Mint More</div>
                </div>
            
            </div>
            
        </div>
    )
}

function handle_resize() {

    let minting_container = document.querySelector('.minting_container');
    
    minting_container.style.height = `${958 * minting_container.getBoundingClientRect().width / 1000}px`;
    if (window.innerWidth <= 350 && !no_info) {
        minting_container.style.marginBottom = '60px';
    }
    else if (window.innerWidth <= 390 && !no_info) {
        minting_container.style.marginBottom = '70px';
    }
    else {
        minting_container.style.marginBottom = '0px';
    }
}

function Mint_Test_Page() {

    useEffect(() => {

        

        

        handle_resize();
        window.addEventListener("resize", handle_resize);
        // window.onresize = handle_resize;

        let animation_layer = document.querySelector('.animation_layer')

        for (let i = 0; i < 4; i++) {

            let img = document.createElement('img');
            img.src = smoke_clouds[0];
            img.className = 'smoke_cloud';
            img.style = `animation: 1s infinite linear animated_cloud; animation-delay: ${i/4}s`;
            animation_layer.appendChild(img);
            //<img className='smoke_cloud' src={smoke_0}/>
        }
        
        
    },[]);


    return (
        <div>
            <div className='minting_container'>
                <Minting_UI/>
            </div>

        </div>
    )
}

/*
function Mint_Test_Page() {

    const { activateBrowserWallet, account } = useEthers()

    const [mintingInfo, setMintingInfo] = useState(null);

    useEffect(() => {
        if (!mintingInfo) {
            info().then(data => setMintingInfo(data));
        }
        
    });

    return (
        <div>
            <div>
                <h3>Wallet Connection</h3><br/>
                {!account && <button onClick={() => activateBrowserWallet()}>Connect</button>}
                {account && <p>Account: {account}</p>}
                <br/>
            </div>
            <div>
                <h3>Minting Info</h3><br/>

                <br/>
            </div>
        </div>
        
    )


    // function useTotalSupply(tokenAddress) {
    //     const { value, error } = useCall(tokenAddress && {
    //       contract: contract,
    //       method: 'totalSupply',
    //       args: []
    //     }) ?? {}
    //     if(error) {
    //       console.error(error.message)
    //       return undefined
    //     }
    //     return value?.[0]
    //  }


    // let mint_items = useContractFunction(contract, 'mint', { transactionName: 'mint' });
    // let mint_function = mint_items.send;

    // let mint_admin_items = useContractFunction(contract, 'adminMint', { transactionName: 'adminMint' });
    // let mint_admin_function = mint_admin_items.send;
    

    // const { activateBrowserWallet, account } = useEthers()
    // // const { sendTransaction, state } = useSendTransaction()

    // return (
    //     <div>
    //         <div>
    //             <button onClick={() => activateBrowserWallet()}>Connect</button>
    //         </div>
    //         {account && <p>Account: {account}</p>}
    //         <div>
    //             <h3>Mint</h3>
    //             <input type="number" id="mint_qty" />
    //             <button onClick={() => {
    //                     let qty = parseInt(document.querySelector('#mint_qty').value);
    //                     let price = 0.06;
    //                     mint_function(qty, { value: utils.parseEther((price*qty).toString()) })
    //                 }
    //             }>Mint</button>
    //         </div>
    //         <div>
    //             <h3>Mint Admin</h3>
    //             <input type="number" id="mint_qty_admin" />
    //             <button onClick={() => {
    //                 let qty = parseInt(document.querySelector('#mint_qty_admin').value);
    //                 mint_admin_function(qty)
    //             }
    //             }>Mint</button>
    //         </div>
            
    //     </div>
    // )
}
*/
export default Mint_Test_Page;