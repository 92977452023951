import axios from 'axios';

let endpoint = `https://us-central1-luckydoods-e9ac3.cloudfunctions.net/api`;
// let endpoint = 'http://localhost:8888';

// -- MINTING
export async function info() {
    let target = `${endpoint}/minting/info`;
    let response = await axios.get(target);
    return response.data;
}

export async function check_password(password) {
    let target = `${endpoint}/minting/check_password`;
    let response = await axios.post(target, { password });
    return response.data;
}

export async function mint_auth(password, to) {
    let target = `${endpoint}/minting/mint_auth`;
    let response = await axios.post(target, { password, to });
    return response.data;
}

export async function create_cross_mint_wallet(email) {
    let target = `${endpoint}/minting/create_cross_mint_wallet`;
    let response = await axios.post(target, { email });
    return response.data;
}

export async function whats_ip() {
    let target = `https://api64.ipify.org`
    let response = await axios.get(target)
    return response.data
}
