// -- STYLES
import '../styles/common.css';
import '../styles/containers.css';
import '../styles/header.css';
import '../styles/minting_bar.css';
import '../styles/image_gallery.css';
import '../styles/intro_component.css';

// -- COMPONENTS
import Footer_Component from '../components/footer.js';
import Header_Component from '../components/header.js';

// -- STYLES
import '../styles/common.css';
import '../styles/containers.css';
import '../styles/header.css';
import '../styles/minting_bar.css';
import '../styles/image_gallery.css';
import '../styles/intro_component.css';

function Privacy_Text() {
    return (
        <div className='component component_magin_top standard_text'>
            <h2>Privacy</h2>
            <p><br/></p>
            <p>Privacy Policy</p>
            <p><br/></p>
            <p><strong>OVERVIEW</strong></p>
            <p><br/></p>
            <p>Throughout the site, the terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo;, refer to 100 Fire Limited, also referred here to as &ldquo;LUCKY DOODS&rdquo;. LUCKY DOODS offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here. By visiting our site, you engage in our &ldquo;Service&rdquo; and agree to be bound by this Privacy Policy. Please read this Privacy Policy carefully before accessing or using our website. If you do not agree with this policy, then do not use this website.</p>
            <p><br/></p>
            <p>You can review the most current version of this Privacy Policy at any time on this page. We reserve the right to update, change or replace any part of this Privacy Policy by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
            <p><br/></p>
            <p><strong>PRIVACY POLICY</strong></p>
            <p><br/></p>
            <p>LUCKY DOODS promotes software subscription services through this website. We have adopted this Privacy Policy to guide you through the collection, use, retention, and disclosure of Personally Identifiable Information (&ldquo;Personal Data&rdquo;, as further described below) that you may provide while using LUCKY DOODS websites and services in connection with our services (collectively referred to as &ldquo;Services&rdquo;).</p>
            <p><br/></p>
            <p>LUCKY DOODS encourages you to read this Privacy Policy, as well as our Terms of Use and Service, in order to understand how we collect and process Personal Data in the course of providing the Services and your interaction with the Services.</p>
            <p><br/></p>
            <p>By using the Services, you consent to the collection, use, retention, and disclosure of your Personal Data for processing as described in, and subject to the limitations set out in, this Privacy Statement.</p>
            <p><br/></p>
            <p><strong>Note to Residents of the European Union:</strong></p>
            <p><br/></p>
            <p>In order to comply with the requirements of the European General Data Protection Regulation (GDPR) for our European users, this Privacy Statement outlines the legal basis on which we process your Personal Data and provides other information required by the GDPR.</p>
            <p><br/></p>
            <p><strong>Note to Californian residents:</strong></p>
            <p><br/></p>
            <p>We also comply with CCPA requirements. As such, If you are a California resident, you may ask us to disclose what personal information we have about you and what we do with that information, to delete your personal information and not to sell your personal information. You also have the right to be notified, before or at the point we collect your personal information, of the types of personal information we are collecting and what we may do with that information. We cannot discriminate against you for exercising your rights under the CCPA. We cannot make you waive these rights, and any contract provision that says you waive these rights is unenforceable.</p>
            <p><br/></p>
            <p><strong>PURPOSE</strong></p>
            <p><br/></p>
            <p>Protecting your privacy is important to us. Personal Data is any information that would identify a person directly, or indirectly in combination with data from other sources. For example, a full name, home or work address, phone number, national identification number (SSN, SIN, etc.), email address, banking details, IP address, biometric data, usage data, or any information that may individually identify a person.</p>
            <p><br/></p>
            <p>LUCKY DOODS may collect Personal Data including without limitation your name, email address, IP address, and device identifiers and/or geolocation information, in the course of its Services, and may use or disclose that Personal Data as described with this Privacy Policy.</p>
            <p><br/></p>
            <p>We may also create de-identified or anonymised data from Personal Data by excluding data components (such as your name, email address, etc.) that makes the data able to personally identify you, through obfuscation, or through other means. In addition, we may collect and use aggregated, anonymous information to provide data about the Services to advertisers, potential business partners, and other unaffiliated entities. As this information does not identify a person and is therefore not Personal Data, LUCKY DOODS when using such aggregated, anonymised and/or de-identified data is not subject to this Privacy Statement.</p>
            <p><br/></p>
            <p><strong>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</strong></p>
            <p><br/></p>
            <p>When you use our website, we may collect the personal information that you voluntarily give us such as your name, email address, password, API key, all Proxy Traffic (if applicable) (including all data and metadata you transmit through our Proxy service).</p>
            <p><br/></p>
            <p>We may also automatically receive your computer&rsquo;s internet protocol (IP) address, browser information, operating system, and hardware information, in order to provide us with information that helps us provide you with a better experience on our website, as well as for the purpose of preventing the usage of bots. Your consent is implied to collect and use such Data to facilitate that use or complete that transaction initiated by you only.</p>
            <p><br/></p>
            <p><strong>SECTION 2 &ndash; CONSENT</strong></p>
            <p><br/></p>
            <p><strong>How do you get my consent?</strong></p>
            <p><br/></p>
            <p>If we ask for your personal information for a commercial reason, like for marketing purposes, we will either ask you directly for your expressed consent or provide you with an opportunity to say no.</p>
            <p><br/></p>
            <p><strong>How do I withdraw my consent?</strong></p>
            <p><br/></p>
            <p>If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting our Privacy Compliance Officer (See contact at the end of this page).</p>
            <p><br/></p>
            <p><strong>SECTION 3 &ndash; DISCLOSURE</strong></p>
            <p><br/></p>
            <p>We may disclose your personal information to government authorities if we are required by law to do so or if you violate our Terms of Service.</p>
            <p><br/></p>
            <p><strong>SECTION 4 - THIRD-PARTY SERVICE</strong></p>
            <p><br/></p>
            <p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third-party service providers, have their own privacy policies in respect to the information they process.</p>
            <p><br/></p>
            <p>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. So, if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</p>
            <p><br/></p>
            <p>As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act. Once you leave our store&rsquo;s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website&rsquo;s Terms of Service.</p>
            <p><br/></p>
            <p>The following is a list of Data services engaged by LUCKY DOODS that may receive Personal Data:</p>
            <br/>- GOOGLE CLOUD<br/>- FACEBOOK (META) and INSTAGRAM<br/>- GOOGLE ANALYTICS<br/>- GOOGLE ADS<br/>- TWITTER ANALYTICS
            <p><br/></p>
            <p>When you click on links and/or hyperlinked buttons on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>
            <p><br/></p>
            <p><strong>SECTION 5 &ndash; SECURITY</strong></p>
            <p><br/></p>
            <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>
            <p><br/></p>
            <p><strong>SECTION 6 &ndash; COOKIES</strong></p>
            <p><br/></p>
            <p>We may use a number of cookies, and when/if we do, you can choose if you want to opt-out of cookies or not.</p>
            <p><br/></p>
            <p><strong>SECTION 7 - TRANSFER OF YOUR PERSONAL DATA TO OTHER COUNTRIES</strong></p>
            <p><br/></p>
            <p>The data that LUCKY DOODS processes, as well as data processed by associated services and providers, may be stored in servers located in the USA, CANADA, Europe and/or elsewhere. It may be stored in multiple locations. However, we ensure that regardless of the place where it is stored, your data is stored in a way that is consistent with the standards of protection required under applicable law.</p>
            <p><br/></p>
            <p>Where Personal Data is transferred from the European Economic Area to a country that has not received an adequacy decision by the European Commission, LUCKY DOODS relies on appropriate safeguards, such as for example the European Commission-approved Standard Contractual Clauses and EU-U.S. Privacy Shield Frameworks, to transfer the Personal Data. By using our Services and submitting your Personal Data, you agree to the transfer, storage, and/or processing of your Personal Data in the locations contemplated above. Where and as required, we will seek your explicit consent as outlined in this Privacy Statement.</p>
            <p><br/></p>
            <p><strong>SECTION 8 - CHANGES TO THIS PRIVACY POLICY</strong></p>
            <p><br/></p>
            <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>
            <p><br/></p>
            <p>If our website or company is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you. We reserve the right to modify this privacy policy at any time, so please review it frequently.</p>
            <p><br/></p>
            <p><strong>SECTION 9 &ndash; RIGHTS CONCERNING PERSONAL DATA</strong></p>
            <p><br/></p>
            <p>LUCKY DOODS is committed to ensuring you retain full access to and control of your Personal Data. To that end, we endeavour to respect your right to be informed regarding the collection, use and disclosure of Personal Data, and your right of correction and access to it, via this Privacy Statement. If you would like to access, correct, remove, request a copy of, withdraw consent to collection of your Personal Data, or are looking for any additional information on how your Personal Data may be collected, used or disclosed by LUCKY DOODS, please contact our Privacy Compliance Officer (See contact at the end of this page). Subject to certain exceptions and limitations that may be prescribed by applicable law, you will be provided with reasonable access to your Personal Data, and will be entitled to have it amended or corrected as appropriate. In certain circumstances, you may have the right to have your Personal Data, or certain components of your Personal Data, erased by LUCKY DOODS, to have your Personal Data moved, copied or transmitted from our systems to other systems, or to object to or restrict certain processing of your Personal Data by us. In the event that you wish to inquire about, or seek to exercise any of these rights (as they may be applicable), please contact our Privacy Compliance Officer at: <a href="mailto:contactluckydoods@gmail.com">contactluckydoods@gmail.com</a></p>
            <p><br/></p>

        </div>
    )
}

function Privacy_Page() {
    return (
        <div>
            <Header_Component/>
            {Privacy_Text()}
            <Footer_Component/>
        </div>
    )
}

export default Privacy_Page;