import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Mainnet, DAppProvider, useEtherBalance, useEthers, Config, Goerli } from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import { getDefaultProvider } from 'ethers'
import Home_Page from "./pages/home"
import TOS_Page from "./pages/terms"
import Privacy_Page from "./pages/privacy"
import Admin_Page from "./pages/admin"
import Mint_Test_Page from "./pages/minting_test"
import New_Home_Page from "./pages/new_home"

// import { MetamaskConnect } from './components/MetamaskConnect'

const config = {
  readOnlyChainId: Mainnet.chainId,
  // readOnlyChainId: Goerli.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://weathered-hidden-hexagon.quiknode.pro/66b3c37d1f996b602972bc43aa9441c0c9808c05/',
    // [Goerli.chainId]: 'https://special-divine-dinghy.ethereum-goerli.quiknode.pro/d1b68d2a91dd9fca5309cbad2e85de1d3c55b251/',
  },
  pollingInterval: 15000
  // pollingInterval: 1000000
}

ReactDOM.render(
  <DAppProvider config={config}>

    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home_Page />}/> */}
        <Route path="terms" element={<TOS_Page />}/>
        <Route path="privacy" element={<Privacy_Page />}/>
        <Route path="admin" element={<Admin_Page />}/>
        <Route path="Mint_Test_Page" element={<Mint_Test_Page />}/>

        <Route path="/" element={<New_Home_Page />}/>
      </Routes>
    </BrowserRouter>

  </DAppProvider>,
  document.getElementById('root')
)
