import React, { useState, useEffect } from 'react';

// import { utils } from 'ethers'
// import { Contract } from '@ethersproject/contracts'
// import { useEthers, useContractFunction } from '@usedapp/core'

// import { abi } from '../assets/smartcontracts/abi';

// -- COMPONENTS
import Footer_Component from '../components/footer.js';
import Header_Component from '../components/header.js';

// -- STYLES
import '../styles/common.css';
import '../styles/containers.css';
import '../styles/header.css';
import '../styles/minting_bar.css';
import '../styles/image_gallery.css';
import '../styles/intro_component.css';





// -- ICONS

import night_mode from '../assets/icons/night_mode.png';
import light_mode from '../assets/icons/light_mode.png';

// -- LAYER INFO
import { layer_data, doods_dna } from '../assets/data/layer_data.js'
import LZString from 'lz-string';

// console.log('here')
// console.log(LZString.decompressFromBase64(layer_data[0][0]))

// -- PREVIEW IMAGES
let images = [];
for (let i = 0; i < 25; i++) {
    images.push(require(`../assets/previews/${i}.png`))
}

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

shuffleArray(images);

// const contract_interface = new utils.Interface(abi);
// const contract_address = '0xfeb45ab8af40cbe6bcd6591643d1f3a65a07aead';
// const contract = new Contract(contract_address, contract_interface)


const current_price = 0.05;
const current_max = 10;
const current_minted = 2;


// -- FUNCTIONALITY
let root = document.querySelector(':root');
function change_lighting(dark_mode) {
    root.style.setProperty('--light_gray', dark_mode ? '#353535' : 'rgb(239, 239, 239)');
    root.style.setProperty('--text_color', dark_mode ? 'white' : 'black');
    root.style.setProperty('--background_color', dark_mode ? 'black' : 'white');
    document.querySelector('.header_line_component').style.display = dark_mode ? 'block' : 'none';
}






function go_to_ordinals() {
    window.open('https://ordinals.com/content/21aa94f671d6e8d1dd9a23a77b6a0a2c355ecf08e536f6e4ece25a6a9c0928bei0?lighting_mode=dark&token_id=0');
}


// -- HOME PAGE COMPONENTS





let image_index = 16;
function increment_image() {
    clearInterval(play_interval);
    image_index = image_index < 24 ? image_index+1 : 16;
    document.querySelector('.full_image').src = images[image_index];
}
function decrement_image() {
    clearInterval(play_interval);
    image_index = image_index > 16 ? image_index-1 : 24;
    document.querySelector('.full_image').src = images[image_index];
}
function scroll_to_gallery() {
    document.querySelector('.gallery_top').scrollIntoView({
        behavior: 'smooth'
    });
}

function Intro_Component() {
    return (
        <div>
            <div className="component component_magin_top">
                <div className="two_thirds_container">
                    <div className='inner_text_left standard_text'>
                        <h2>We're the Lucky Doods, hi, GM!</h2>
                        <p>We're the Lucky Doods, we're degen!</p>
                        <br/>
                        <h3>Be part of Bitcoin Blockchain history</h3><br/>
                        <p>Lucky Doods is pioneering Ordinals the same way Crypto Punks paved the way for NFTs on Ethereum with the ERC721 standard. The Lucky Doods are proud to indroduce LDS721 - the luckiest standard for NFTs on Bitcoin.</p>
                        <div className='desktop_only'>
                            <br/>
                            <h3>First Ever Bitcoin Ordinal collection to:</h3><br/>
                            <p>🍀 Group all the NFTs in the collection into a single inscription, in a similar way to an Ethereum smart contract.</p>
                            <br/>
                            <p>🍀 Create a unique URL scheme (address) for each NFT within the collection, making them easily linkable and shareable.</p>
                            <br/>
                            <p>🍀 Inscribe together forever the attributes and rarities of each NFT in the collection on chain.</p>
                            <br/>
                            <h3>Other Awesome Features</h3><br/>
                            <p>🍀 Easily tradable on OpenSea and most NFT marketplaces as wrapped Ethereum NFTs.</p>
                            <br/>
                            <p>🍀 Includes two visualizations, an animated DNA representation on Bitcoin and a cartoonish depiction on Ethereum.</p>
                        </div>
                        {/* <div className='desktop_only'>
                            <br/>
                            <h3>Join the Lucky Doods life</h3>
                            <p>Are you crypto since day zero?</p>
                            <p>An incognito hero? Early like nakamoto?</p>
                            <p>Won the photo loto? More NFTs than Cozimo?</p>
                            <p>No FOMO! Join the Lucky Doods life.</p>
                            <br/>
                            <h3>Lucky Doods Anthem</h3>
                            <p>Like this cartoon? Then listen to our epic tune</p>
                            <br/>
                            <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1443964165&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                        </div> */}
                    </div>
                </div><div className="one_third_container">

                    <div className="bottom_floated_container standard_text">
                        <img className="full_image standard_curve" src={images[16]}/>
                        <div className="cycle_container">
                            <div className='token_number_text'><b>Preview</b><br/><u className='tiny_font' onClick={scroll_to_gallery}>More Previews</u></div>
                            <div className='navigation_button chuncky_gray standard_curve' onClick={increment_image}>→</div><div className='navigation_button chuncky_gray standard_curve' onClick={decrement_image}>←</div>
                        </div>
                    </div>
                    

                </div>
                
            </div>
            <div className='component mobile_only border_top component_magin_top_large component_magin_bottom_large'>
                <br/>
                <h3>First Ever Bitcoin Ordinal collection to:</h3><br/>
                <p>🍀 Group all the NFTs in the collection into a single inscription, in a similar way to an Ethereum smart contract.</p>
                <br/>
                <p>🍀 Create a unique URL scheme (address) for each NFT within the collection, making them easily linkable and shareable.</p>
                <br/>
                <p>🍀 Inscribe together forever the attributes and rarities of each NFT in the collection on chain.</p>
                <br/>
                <h3>Other Awesome Features</h3><br/>
                <p>🍀 Easily tradable on OpenSea and most NFT marketplaces as wrapped Ethereum NFTs.</p>
                <br/>
                <p>🍀 Includes two visualizations, an animated DNA representation on Bitcoin and a cartoonish depiction on Ethereum.</p>
            </div>
            <div className='component border_top component_magin_top_large component_magin_bottom_large'>
                
                <h2>Lucky Doods Anthem</h2>
                <p>Like this cartoon? Then listen to our epic tune</p>
                <br/>
                <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1443964165&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            </div>
        </div>
    )
}

function Minting_Info_Component() {
    return (
        <div className="component component_magin_top border_bottom border_top">
            {/* <Minting_Bar_Component/><div className="two_thirds_container"> */}
            <div className="two_thirds_container">
                <div className='inner_text_left standard_text'>
                    <h2>Distribution</h2>
                    <p>An exclusive 1,000 pieces capped collection.</p>
                    <p>More information to be announced soon.</p>
                    <p>Claim OG role on our Discord to get WL early mint.</p>
                    <p>Limited Spots available.</p>
                </div>
            </div>
            <div className="one_third_container">
                <div className='inner_text_right standard_text tiny_font'>
                    <p>Load up your wallets because it's never too soon!</p>
                    <p>If you like our tune and are an Eth tycoon,</p>
                    <p>Get on the whitelist to get your cartoon,</p>
                    <p>Lucky Doods to the moon!</p>
                </div>
            </div>
            
        </div>
    )
}

function Minting_Bar_Component() {
    return (
        <div className="one_third_container">
            <div className='minting_bar chuncky_gray standard_curve'></div>
        </div>
    )
}

/*
let stop_rendering = false;
async function delete_previous_dude() {
    stop_rendering = true;
    let image_layers = document.querySelectorAll('.image_layer');
    for (let i = 0; i < image_layers.length; i++) {
        let image_layer = image_layers[i];
        image_layer.remove();
    }
    stop_rendering = false;
}


function make_doods(dude_index) {

    delete_previous_dude();

    // document.querySelector('.test_layer').src = `export/${dude_index}.png` // -- TESTING CALIBRATION

    let dude_data = doods_dna[dude_index]
    let temp_layers = [];
    let image_layers = [];

    for (let i = 0; i < layer_data.length; i++) {
        let layer_type = layer_data[i]
        let layer = layer_type[dude_data[i]];
        let decompressed = LZString.decompressFromBase64(layer)
        let image_layer = document.createElement('div');
        image_layer.className = 'image_layer';
        image_layer.innerHTML = decompressed;
        layer_container.appendChild(image_layer);

        let span_content = [];
        let spans = image_layer.querySelectorAll('span');

        for (let x = 0; x < spans.length; x++) {
            let span = spans[x];
            span_content.push(span.innerHTML);
            span.innerHTML = '';
        }

        temp_layers.push(span_content)
        image_layers.push(image_layer)

    }



    async function animate_drawing() {
        for (let i = 0; i < temp_layers.length; i++) {
            
            if (stop_rendering) { break; }

            let span_content = temp_layers[i];
            let image_layer = image_layers[i];

            let spans = image_layer.querySelectorAll('span');

            for (let x = spans.length-1; x >= 0; x--) {

                if (stop_rendering) { break; }

                let span = spans[x];
                span.innerHTML = span_content[x];
                await sleep(1)
            }
        }
    }

    animate_drawing();
}
*/

let inscription_index = 0;
let currently_rendering = -1;
let inscriptions_map = [7,10,24,27,30,32,37,38]
let stop_rendering = false;

function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

function delete_previous_dude() {
    stop_rendering = true;
    let image_layers = document.querySelectorAll('.image_layer');
    for (let i = 0; i < image_layers.length; i++) {
        let image_layer = image_layers[i];
        image_layer.remove();
    }
    stop_rendering = false;
}

function render_dood() {

    let layer_container = document.querySelector('.layer_container');

    

    let temp_layers = [];
    let image_layers = [];

    let dood_index = inscriptions_map[inscription_index]

    if (currently_rendering == dood_index) {
        return;
    }

    delete_previous_dude();
    currently_rendering = dood_index;

    let layers = doods_dna[dood_index]

    for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];
        let layer_raw_data = layer_data[i][layer];
        let decompressed = LZString.decompressFromBase64(layer_raw_data);

        let image_layer = document.createElement('div');
        image_layer.className = 'image_layer';
        image_layer.innerHTML = decompressed;
        layer_container.appendChild(image_layer);

        let span_content = [];
        let spans = image_layer.querySelectorAll('span');

        for (let x = 0; x < spans.length; x++) {
            let span = spans[x];
            span_content.push(span.innerHTML);
            span.innerHTML = '';
        }

        temp_layers.push(span_content)
        image_layers.push(image_layer)

    }

    async function animate_drawing() {
        for (let i = 0; i < temp_layers.length; i++) {
            
            if (stop_rendering) { break; }

            let span_content = temp_layers[i];
            let image_layer = image_layers[i];

            let spans = image_layer.querySelectorAll('span');

            for (let x = spans.length-1; x >= 0; x--) {

                if (stop_rendering) { break; }

                let span = spans[x];
                span.innerHTML = span_content[x];
                await sleep(1)
            }
        }
    }

    animate_drawing();

    // console.log(LZString.decompressFromBase64(layer_data[0][0]))
    // console.log(layers)
}

let previous_size = -1;
let has_handled_resize = false;

function resize_handler() {

    if (window.innerWidth == previous_size) {
        return;
    }
    previous_size = window.innerWidth;

    let layer_container = document.querySelector('.layer_container')
    if (layer_container) {
        let parent_width = layer_container.parentElement.getBoundingClientRect().width;
        layer_container.parentElement.style.height = `${parent_width + 70}px`;
        
        layer_container.style.top = `${(parent_width-300)/2}px`
        layer_container.style.right = `${(parent_width-300)/2}px`
        layer_container.style.transform = `scale(${parent_width / 300})`
    }
}

function Dood_DNA() {

    useEffect(()=>{
        render_dood();
        resize_handler();
        if (!has_handled_resize) {
            has_handled_resize = true;
            window.onresize = resize_handler;
        }
    });


    return (
        <div className="layer_container standard_curve">

        </div>
    )
}

//iframe_container


shuffleArray(inscriptions_map)

function increment_inscription() {
    inscription_index = inscription_index < inscriptions_map.length-1 ? inscription_index+1 : 0;
    render_dood();
    // document.querySelector('.iframe_ordinals').src = `https://ordinals.com/content/21aa94f671d6e8d1dd9a23a77b6a0a2c355ecf08e536f6e4ece25a6a9c0928bei0?lighting_mode=light&token_id=${inscriptions_map[inscription_index]}`;
}
function decrement_inscription() {
    inscription_index = inscription_index > 0 ? inscription_index-1 : inscriptions_map.length-1;
    render_dood();
    // document.querySelector('.iframe_ordinals').src = `https://ordinals.com/content/21aa94f671d6e8d1dd9a23a77b6a0a2c355ecf08e536f6e4ece25a6a9c0928bei0?lighting_mode=light&token_id=${inscriptions_map[inscription_index]}`;
}

function Project_Specs_Component() {

    // function resize_handler() {
    //     let iframe_container = document.querySelector('.iframe_container')
    //     if (iframe_container) {
    //         let parent_width = iframe_container.parentElement.getBoundingClientRect().width;
    //         iframe_container.parentElement.style.height = `${parent_width + 70}px`;
            
    //         iframe_container.style.top = `${(parent_width-400)/2}px`
    //         iframe_container.style.right = `${(parent_width-400)/2}px`
    //         iframe_container.style.transform = `scale(${parent_width / 400})`
    //     }
    // }

    // useEffect(()=>{
    //     resize_handler();
    //     window.onresize = resize_handler;
    // });

    return (
        <div className="component component_magin_top border_bottom">
            <div className="two_thirds_container">
                <div className='inner_text_left standard_text'>
                    <h2>Lucky Dood DNA</h2>

                    <p>Lucky Doods are made up of up to 8 attributes with different skins, clothes, beards, eyes, mouths, earrings, hats, and even sunglasses</p>
                    <br/>
                    <p>On the Bitcoin Ordinal visualisation tool, these attributes are depicted in superimposed layers of color made up of symbols and alphanumerical elements.</p>
                    <br/>
                    <p>On the Ethereum chain, these same elements are depicted in a cartoonish format like the images in the gallery below</p>
                    <br/>
                    {/* <p>🍀 6 Types of Skins</p>
                    <p>🍀 14 Types of Clothing</p>
                    <p>🍀 9 Types of Beards</p>
                    <p>🍀 8 Types of Eyes</p>
                    <p>🍀 14 Types of Mouths</p>
                    <p>🍀 11 Types of Earrings</p>
                    <p>🍀 18 Types of Hats</p>
                    <p>🍀 9 Types of Sunglasses</p> */}

                </div>
            </div><div className="one_third_container">

                <div className="bottom_floated_container">
                    {/* <div className="iframe_container standard_curve">
                        <iframe className='iframe_ordinals' scrolling="no" frameborder="none" src={`https://ordinals.com/content/21aa94f671d6e8d1dd9a23a77b6a0a2c355ecf08e536f6e4ece25a6a9c0928bei0?lighting_mode=light&token_id=${inscriptions_map[inscription_index]}`}></iframe>
                    </div> */}

                    <Dood_DNA/>

                    <div className="cycle_container stick_to_bottom">
                        <div className='token_number_text'><b>Forever On Bitcoin</b><br/><u className='tiny_font' onClick={go_to_ordinals}>View on ordinals.com</u></div>
                        <div className='navigation_button chuncky_gray standard_curve' onClick={increment_inscription}>→</div><div className='navigation_button chuncky_gray standard_curve' onClick={decrement_inscription}>←</div>
                    </div>
                    
                    {/* <img className="full_image" src={images[16]}/> */}
                </div>


            </div>
        </div>
    )
}

function Image_Gallery_Component() {
    return (
        <div className="component component_magin_top gallery_top">
            <div className='inner_text_left standard_text'>
                <h2>Image Gallery</h2>
                <p>Put on your specticals,</p>
                <p>To view our unforgettable collectibles,</p>
                <p>Truely 1 of 1 non-fungibles,</p>
                <p>They're two dimensionals!</p>
            </div>
            <div className='gallery_container'>
            {
                (function() {
                    let rows = [];
                    for (let i = 0; i < 16; i++) {

                        if (i%4 == 3) {
                            rows.push(<img className="gallery_image standard_curve" src={images[i]} key={i}/>);
                        }
                        else {
                            rows.push(<img className="gallery_image gallery_image_margin_right standard_curve" src={images[i]} key={i}/>);
                        }

                        
                    }
                    return rows;
                })()
                
            } 
            </div>
        </div>
    )
}

let play_interval;

function Home_Page() {

    // const { state, send } = useContractFunction(contract, 'mint', { transactionName: 'mint' })

    // const mint_test = () => {
    //     send(2, { value: utils.parseEther("0.1") })
    // }

    useEffect(() => {
        clearInterval(play_interval);
        play_interval = setInterval(function() {
            
            image_index = image_index < 24 ? image_index+1 : 16;
            document.querySelector('.full_image').src = images[image_index];
        },1000);


        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            try {
                change_lighting(true);
            } catch (error) {}
            
        }
        
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            change_lighting(event.matches);
        });
    });

    // useEffect(() => {
    //     console.log(state)
    // }, [state])

    
    // const { activateBrowserWallet, account } = useEthers()
    // // const { sendTransaction, state } = useSendTransaction()

    // return (
    //     <div>
    //         <div>
    //             <button onClick={() => activateBrowserWallet()}>Connect</button>
    //         </div>
    //         {account && <p>Account: {account}</p>}
    //         <div>
    //             <button onClick={() => mint_test()}>Mint</button>
    //         </div>
    //     </div>
    // )

    return (
        <div>
            <Header_Component/>
            <Intro_Component/>
            <Minting_Info_Component/>
            <Project_Specs_Component/>
            <Image_Gallery_Component/>
            <Footer_Component/>
        </div>
    )
}

export default Home_Page;