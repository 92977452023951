
import full_logo from '../assets/images/full_logo.png';
import discord_white from '../assets/icons/discord_white.png';
import twitter_white from '../assets/icons/twitter_white.png';

function go_to_discord() {
    window.open('https://discord.gg/3949tmHrNu')
}

function go_to_twitter() {
    window.open('https://twitter.com/luckydoods');
}

function go_to_home() {
    window.location = '/'
}

function Header_Component() {
    return (
        <div className='header_container'>
            <div className='component'>
                <img className='logo' src={full_logo} onClick={go_to_home}/>
                <div className='social_button chuncky_blue standard_curve'  onClick={go_to_twitter}>
                    <img src={twitter_white} className="social_button_icon"/>
                    <div className='social_button_text'>Twitter</div>
                </div>
                <div className='social_button chuncky_pink standard_curve' onClick={go_to_discord}>
                    <img src={discord_white} className="social_button_icon"/>
                    <div className='social_button_text'>Discord</div>
                </div>
                
            </div>
            <div className='border_bottom header_line_component'></div>
        </div>
    )
}

export default Header_Component;