import rainbow from '../assets/images/rainbow.png';

function go_to_discord() {
    window.open('https://discord.gg/3949tmHrNu')
}

function go_to_terms() {
    window.location = 'terms'
}

function go_to_privacy() {
    window.location = 'privacy'
}

function go_to_smart_contract() {
    window.open('https://etherscan.io/address/0x44cdc48b031f5267316b6a4dae1904225ab95256')
}

function go_to_ordinal() {
    window.open('https://ordinals.com/content/21aa94f671d6e8d1dd9a23a77b6a0a2c355ecf08e536f6e4ece25a6a9c0928bei0?lighting_mode=dark&token_id=0')
}

function Footer_Component() {
    return (
        <div className="component component_magin_top">
            <img src={rainbow} className="full_image"/>
            <div className='inner_text standard_text'>
                <h2>We're the Lucky Doods, bye, GN!</h2><br/>
                <p>Prices of NFTs may go up or down,</p>
                <p>So you shouldn't frown if it does go down,</p>
                <p>Don't be a loon, this is just a cool cartoon,</p>
                <p>But hopefully soon, these will go to the moon!</p><br/>
                <p><u onClick={go_to_terms}>Terms</u> | <u onClick={go_to_privacy}>Privacy</u> | <u onClick={go_to_discord}>Contact</u></p><br/>
                <p>Copyright 2023 Lucky Doods</p>
                <br/>
                <u onClick={go_to_smart_contract}>Verified Smart Contract</u>
                <br/>
                <u onClick={go_to_ordinal}>Bitcoin Ordinals Inscription</u>
            </div>
        </div>
    )
}

export default Footer_Component;